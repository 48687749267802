html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
}
